import React from 'react';

import { Paragraph } from 'src/components/Typography/index';
import colors from 'src/style/colors';

import SkillDescription from 'src/components/Bootcamp/Schedule/Program/SkillDescription';

const SkillLogo = ({
  svg,
  name,
  description,
  handleMouseEnter,
  handleMouseLeave,
  isHover,
  small,
}) => {
  return (
    <div css={{ position: 'relative' }}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        role="button"
        tabIndex={0}
        aria-label="Toggle skill"
        css={{ scale: small ? '75%' : 'none' }}
      >
        {svg}
      </div>

      <SkillDescription isHover={isHover}>
        <Paragraph css={{ padding: 0, color: colors.white, fontWeight: 'bold' }}>{name}</Paragraph>
        <Paragraph
          css={{
            padding: 0,
            color: colors.white,
            fontSize: 16,
            lineHeight: '22px',
          }}
        >
          {description}
        </Paragraph>
      </SkillDescription>
    </div>
  );
};

export default SkillLogo;
