import React, { useState } from 'react';

/* --- import Data ----*/
import ProgramData from 'src/data/Program';
import SkillsLogos from 'src/data/ProgramLogoSkills';

/* --- import Components ----*/
import { TabsListMobile } from 'src/components/Bootcamp/TabsList';

/* --- import Style ----*/
import { mediaQueries } from 'src/style/breakpoints';
import styled from 'src/style';
import { H4, sentenceStyler, Sentence, Paragraph } from 'src/components/Typography';
import colors from 'src/style/colors';
import { SkillLogo } from './index';

export const Container = styled.span(sentenceStyler);

const ProgramSmall = ({ course }) => {
  const [selected, setSelected] = useState(null);

  const program = ProgramData.filter(({ courses }) => {
    return courses.includes(course);
  });

  return (
    <div>
      {program.map(({ title, iconName, colorCode, text, duration }, index) => {
        return (
          <div
            key={title}
            css={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <TabsListMobile
              tabItem={title}
              tabSubtitle={duration ? duration[course] : null}
              onClick={() => {
                setSelected(index === selected ? null : index);
              }}
              isProgramSection={true}
              planetName={iconName}
            >
              {selected === index && (
                <Container
                  css={{
                    marginTop: 20,
                    lineHeight: '25px',
                    color: colors.purpleDark,
                    '& a': { color: colorCode, textDecoration: 'none' },
                    [mediaQueries.phoneOnly]: {
                      fontSize: 17,
                      lineHeight: '25px',
                    },
                  }}
                >
                  <H4 dangerouslySetInnerHTML={{ __html: text }} />
                  {program[selected].hashtag ? (
                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 16,
                        flexWrap: 'wrap',
                      }}
                    >
                      {program[selected].hashtag.map((item) => {
                        return (
                          <Sentence
                            css={{
                              color: program[selected].colorCode,
                              paddingRight: 16,
                            }}
                            key={item}
                          >
                            {item}
                          </Sentence>
                        );
                      })}
                    </div>
                  ) : null}
                  {program[selected].skills ? (
                    <div
                      css={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginTop: 40,
                        gap: 80,
                        justifyContent: 'center',
                        [mediaQueries.phoneOnly]: {
                          /* justifyContent: 'space-between', */
                        },
                      }}
                    >
                      {program[selected].skills.map((skillName) => {
                        if (skillName) {
                          const { name, description, svg } = SkillsLogos.find(
                            (el) => el.name === skillName
                          );
                          return (
                            <div
                              key={name}
                              css={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <SkillLogo
                                /* handleMouseEnter={() => {
                              setSkillHovered(name);
                            }}
                            handleMouseLeave={() => {
                              setSkillHovered(null);
                            }}
                            isHover={skillHovered === name} */
                                svg={svg}
                                name={name}
                                description={description}
                              />
                              <Paragraph
                                css={{
                                  color: '#9d9d9d',
                                  fontSize: 16,
                                  [mediaQueries.phoneOnly]: {
                                    fontSize: 16,
                                  },
                                }}
                              >
                                {name}
                              </Paragraph>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  ) : null}
                </Container>
              )}
            </TabsListMobile>
          </div>
        );
      })}
    </div>
  );
};

export default ProgramSmall;
