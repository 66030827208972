import React from 'react';
import { Section } from '../Section';
import { SectionTitle } from 'src/components/Typography';
import { ProgramProLarge, ProgramProSmall } from '../Bootcamp/Schedule/Program/index';
import { useMediaQueries } from '../../utils/MediaQueries';
import { mediaQueries } from 'src/style/breakpoints';

const ProgramPro = ({ program, days }) => {
  const mqs = useMediaQueries();
  return (
    <Section
      theme="secondary"
      css={{
        paddingTop: 1,
        marginTop: -1,
        [mediaQueries.desktopUp]: {
          paddingTop: 1,
          marginTop: -1,
        },
        [mediaQueries.tabletPortraitUp]: {
          paddingBottom: 250,
        },
        [mediaQueries.phoneOnly]: {
          paddingBottom: 200,
        },
      }}
    >
      <SectionTitle
        css={{
          paddingBottom: 32,
          [mediaQueries.tabletLandscapeUp]: {
            paddingBottom: 50,
          },
        }}
      >
        Le programme{' '}
        <br
          css={{
            [mediaQueries.tabletPortraitUp]: {
              display: 'none',
            },
          }}
        />
        des {days} jours - {days * 7} heures
      </SectionTitle>
      {mqs.tabletLandscapeUp ? (
        <ProgramProLarge program={program} />
      ) : (
        <ProgramProSmall program={program} />
      )}
    </Section>
  );
};
export default ProgramPro;
