import { ProgramLarge, ProgramSmall } from './index';
import { Button } from 'src/components/Button';
import ModalForm from 'src/components/Modal/ModalFormSyllabus';
import React from 'react';
import { Section } from 'src/components/Section';
import { mediaQueries } from 'src/style/breakpoints';
import { useMediaQueries } from 'src/utils/MediaQueries';
import { SectionTitle } from 'src/components/Typography';
import colors from 'src/style/colors';

const Program = React.memo(
  ({
    course,
    id,
    containerProgram,
    // isModalFormOpen,
    // setIsModalFormOpen,
    bootcampType,
    formSuccess,
    setFormSuccess,
    values,
    setValues,
  }) => {
    const [isModalFormOpen, setIsModalFormOpen] = React.useState(false);

    const mqs = useMediaQueries();
    return (
      <div id={id} ref={containerProgram}>
        <Section
          theme="dark"
          css={{
            paddingBottom: 210,
            [mediaQueries.phoneOnly]: {
              paddingBottom: 210,
            },
            [mediaQueries.tabletLandscapeUp]: {
              paddingBottom: 110,
            },
          }}
        >
          {course === 'full-stack' ? (
            <SectionTitle css={{ marginBottom: 80, width: '100%' }} isMobileCenter isCenter>
              Le&nbsp;
              <span css={{ color: colors.yellow }}>
                {/* <br
                  css={{
                    display: 'none',
                    [mediaQueries.phoneOnly]: {
                      display: 'block',
                    },
                  }}
                /> */}
                programme
              </span>{' '}
              de&nbsp;formation
            </SectionTitle>
          ) : null}
          {mqs.tabletLandscapeUp ? (
            <ProgramLarge course={course} />
          ) : (
            <ProgramSmall course={course} />
          )}
          {/* <ProgramLarge course={course} /> */}
          {course === 'full-stack' ? (
            ''
          ) : (
            <div css={{ display: 'flex', justifyContent: 'center', marginTop: 90 }}>
              <Button
                css={{
                  minWidth: 210,
                }}
                onClick={() => {
                  setIsModalFormOpen(!isModalFormOpen);
                }}
              >
                Recevoir le programme de formation
              </Button>
              {isModalFormOpen && (
                <>
                  <ModalForm
                    isOpen={isModalFormOpen}
                    onClose={() => {
                      setFormSuccess(false);
                      setIsModalFormOpen(false);
                    }}
                    fromFormationsPageType={bootcampType}
                    formSuccess={formSuccess}
                    setFormSuccess={setFormSuccess}
                    values={values}
                    setValues={setValues}
                  />
                </>
              )}
            </div>
          )}
        </Section>
      </div>
    );
  }
);
export default Program;
