import React, { useState } from 'react';
import { H4, sentenceStyler } from 'src/components/Typography';
import { TabsTabletLandscapeUp } from 'src/components/Bootcamp/Finance/elements';
import { TabsList } from 'src/components/Bootcamp/TabsList';
import colors from 'src/style/colors';
import styled from 'src/style';
import Check from 'src/components/Icons/tick.svg';

export const Container = styled.span(sentenceStyler);

const ProgramPro = ({ program }) => {
  const [unfolded, setUnfolded] = useState(null);
  const desktopUnfolded = unfolded === null ? 0 : unfolded;

  return (
    <div css={{ backgroundColor: colors.white, borderRadius: 10 }}>
      <TabsTabletLandscapeUp>
        {program.map((item, index) => (
          <TabsList
            key={item.day}
            tab={item.day}
            isOpen={index === desktopUnfolded}
            onClick={() => {
              setUnfolded(index);
            }}
            css={{ letterSpacing: 0.4 }}
          />
        ))}
      </TabsTabletLandscapeUp>
      <ul
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '32px 54px',
        }}
      >
        {program[desktopUnfolded].program.map((item) => (
          <li
            key={item}
            css={{
              display: 'inline-block',
              flexDirection: 'column',
              listStyle: 'none',
              backgroundImage: `url(${Check})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '0% 14px',
              backgroundSize: '3px 1x',
              paddingLeft: 32,
            }}
          >
            <Container
              css={{
                lineHeight: '40px',
              }}
            >
              <H4>{item}</H4>
            </Container>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default ProgramPro;
