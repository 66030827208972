import React from 'react';
import colors from 'src/style/colors';

const SkillDescription = ({ children, isHover }) => {
  return (
    <div
      css={{
        position: 'absolute',
        top: '120%',
        left: '50%',
        marginLeft: '-125px',
        width: 250,
        zIndex: 10,
        visibility: isHover ? 'visible' : 'hidden',
        display: isHover ? 'block' : 'none',
        opacity: isHover ? 1 : 0,
        backgroundColor: colors.purpleDark,
        padding: '15px 15px 15px 15px',
        borderRadius: '5px',
        boxShadow: '2px 2px 6px 1px rgba(0,0,0,0.20)',
        transition: isHover && 'all 0.1s ease-in-out 0.3s',
        '&:before': {
          position: 'absolute',
          content: '""',
          top: '-5px',
          left: '50%',
          width: '11px',
          height: '11px',
          backgroundColor: colors.purpleDark,
          transform: 'translateX(-50%) rotate(45deg)',
          borderRadius: '2px',
        },
      }}
    >
      {children}
    </div>
  );
};
export default SkillDescription;
