import React from 'react';

/* --- import Components ----*/
import { Section } from 'src/components/Section';

/* --- import Style ----*/
import { Paragraph, SectionTitle } from 'src/components/Typography/index';
import { mediaQueries } from 'src/style/breakpoints';
import { useMediaQueries } from 'src/utils/MediaQueries';

/**
 * @type React.FunctionComponent<{ headerDescription: string}>
 */

const ProgramHeader = ({ headerDescription }) => {
  const mqs = useMediaQueries();

  const handleRenderTitle = () => (
    <SectionTitle isCenter>
      Comment se déroule {!mqs.tabletPortraitUp && <br />} la formation ?
    </SectionTitle>
  );

  return (
    <div>
      <Section
        theme="dark"
        css={{ paddingBottom: 0, [mediaQueries.desktopUp]: { paddingBottom: 0 } }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          {handleRenderTitle()}
          <Paragraph css={{ maxWidth: 900 }}>{headerDescription}</Paragraph>
        </div>
      </Section>
    </div>
  );
};
export default ProgramHeader;
