import React, { useState } from 'react';
import { H4, sentenceStyler } from 'src/components/Typography';
import { TabsListMobile } from 'src/components/Bootcamp/TabsList';
import { mediaQueries } from 'src/style/breakpoints';
import styled from 'src/style';
import Check from 'src/components/Icons/tick.svg';

export const Container = styled.span(sentenceStyler);

const ProgramProSmall = ({ program }) => {
  const [unfolded, setUnfolded] = useState(null);
  return (
    <div>
      {program.map((item, index) => (
        <div
          key={item.day}
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TabsListMobile
            tabItem={item.day}
            isUnfolded={unfolded === index}
            onClick={() => {
              setUnfolded((prev) => (prev === index ? null : index));
            }}
          >
            {unfolded === index && (
              <ul>
                {program[index].program.map((item) => (
                  <li
                    key={item}
                    css={{
                      backgroundImage: `url(${Check})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: '0% 7px',
                      paddingLeft: 30,
                      '&:not(:last-child)': {
                        marginBottom: 16,
                      },
                    }}
                  >
                    <Container
                      css={{
                        lineHeight: '25px',
                        [mediaQueries.phoneOnly]: {
                          fontSize: 17,
                          lineHeight: '22px',
                        },
                      }}
                    >
                      <H4>{item}</H4>
                    </Container>
                  </li>
                ))}
              </ul>
            )}
          </TabsListMobile>
        </div>
      ))}
    </div>
  );
};

export default ProgramProSmall;
