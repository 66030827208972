import React, { useState } from 'react';

/* --- import Data ----*/
import ProgramData from 'src/data/Program';
import SkillsLogos from 'src/data/ProgramLogoSkills';

/* --- import Components ----*/
import { SkillLogo } from './index';

/* --- import Style ----*/
import colors from 'src/style/colors';
import { Sentence, TextContainer, Paragraph } from '../../../Typography';
import PlanetIcon from './PlanetIcon';
import { mediaQueries } from 'src/style/breakpoints';

/**
 * @type React.FunctionComponent<{ headerDescription: string}>
 */

const ProgramLarge = ({ course }) => {
  const [selected, setSelected] = useState(0);
  const [skillHovered, setSkillHovered] = React.useState(null);

  const program = ProgramData.filter(({ courses }) => {
    return courses.includes(course);
  });

  return (
    <div
      css={{
        backgroundColor: colors.white,
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 8,
        height: 'auto',
      }}
    >
      <div css={{ backgroundColor: colors.transparentPurple, width: '70%' }}>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          {program.map(({ title, iconName, duration }, index) => {
            return (
              <button
                key={title}
                onClick={() => {
                  setSelected(index);
                }}
                css={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  cursor: 'pointer',
                  backgroundColor: index === selected ? colors.white : null,
                  '&:hover': {
                    backgroundColor: colors.white,
                  },
                  padding: '24px 0px 20px 24px',
                  textAlign: 'left',
                }}
                name={title}
              >
                <div css={{ width: '36px' }}>
                  <PlanetIcon tag={iconName} />
                </div>

                <div css={{ marginLeft: 25 }}>
                  <Sentence
                    css={{
                      fontWeight: 'bold',
                      color: colors.purpleDark,
                      fontSize: 17,
                      lineHeight: '18px',
                    }}
                  >
                    {title}
                  </Sentence>
                  {duration && (
                    <Sentence css={{ color: colors.purpleDark, fontSize: 16 }}>
                      {duration[course]}
                    </Sentence>
                  )}
                </div>
              </button>
            );
          })}
        </div>
      </div>
      <div css={{ width: '100%' }}>
        <div css={{ padding: '32px 64px' }}>
          <TextContainer
            css={{
              color: colors.purpleDark,
              '& a': { color: program[selected].colorCode, textDecoration: 'none' },
            }}
            dangerouslySetInnerHTML={{ __html: program[selected].text }}
          />
          {program[selected].hashtag ? (
            <div css={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {program[selected].hashtag.map((item, index) => {
                return (
                  <Sentence
                    css={{
                      color: program[selected].colorCode,
                      paddingRight: 16,
                    }}
                    key={index}
                  >
                    {item}
                  </Sentence>
                );
              })}
            </div>
          ) : null}

          {program[selected].skills ? (
            <div
              css={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: 80,
                marginTop: 64,
              }}
            >
              {program[selected].skills.map((skillName, index) => {
                if (skillName) {
                  const { name, description, svg } = SkillsLogos.find(
                    (el) => el.name === skillName
                  );
                  return (
                    <div
                      css={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      key={index}
                    >
                      <SkillLogo
                        handleMouseEnter={() => {
                          setSkillHovered(name);
                        }}
                        handleMouseLeave={() => {
                          setSkillHovered(null);
                        }}
                        isHover={skillHovered === name}
                        svg={svg}
                        name={name}
                        description={description}
                      />
                      <Paragraph
                        css={{
                          color: '#9d9d9d',
                          fontSize: 16,
                          [mediaQueries.phoneOnly]: {
                            fontSize: 16,
                          },
                        }}
                      >
                        {name}
                      </Paragraph>
                    </div>
                  );
                }
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProgramLarge;
