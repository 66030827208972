import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

/**
 * @type React.FunctionComponent<{ name: string, big?: boolean }>
 */

const Avatar = ({ name, big, alt }) => {
  return (
    <li
      css={{
        marginRight: big ? 0 : '-15px',
        listStyle: 'none',
      }}
    >
      <GatsbyImage
        image={name}
        alt={alt}
        style={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          boxSizing: 'border-box',
          overflow: 'hidden',
          verticalAlign: 'middle',
          height: big ? 105 : 55,
          borderRadius: '50%',
          width: big ? 105 : 55,
          border: big ? '5px solid #F9FAFA' : '2px solid white',
        }}
      />
    </li>
  );
};

export default Avatar;
